import { MaskConstants } from 'src/app/shared/constants/mask.constants';
import {
  Component,
  Input,
  ViewChild,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { DisplayTextModel } from '@syncfusion/ej2-angular-barcode-generator';
import { GetOrderDto } from 'src/app/dtos/get-order.dto';
import { TransporterLogoFactory } from 'src/app/shared/models/factories/transporter-logo.factory';
import { PubsubService } from 'src/app/shared/services/pubsub.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DATAMatrix } from 'src/app/shared/functions/datamatrix.function';

@Component({
  selector: 'app-tag-correios',
  templateUrl: './tag-correios.component.html',
  styleUrls: ['./tag-correios.component.scss'],
})
export class TagCorreiosComponent implements OnInit, AfterViewInit {
  logoFactory = new TransporterLogoFactory();
  boobleModalData?: GetOrderDto;
  @Input() order?: GetOrderDto;
  isTest = false;
  masks = MaskConstants;

  getMatrix(data: string) {
    const matrix = DATAMatrix({
      msg: data,
      dim: 80,
      rct: 0,
      pad: 0,
      pal: ['#000000', '#fff'],
      vrb: 0,
    });
    return matrix;
  }

  @ViewChild('barcode')
  public displayText!: DisplayTextModel;

  constructor(
    private sanitizer: DomSanitizer,
    private readonly pubsubService: PubsubService
  ) { }

  ngAfterViewInit(): void {
    document
      .getElementById(`datamatrix-${this.order!.id!}`)!
      .appendChild(this.getMatrix(this.order!.label.datamatrix));
  }

  ngOnInit(): void {
    if (this.boobleModalData) this.order = this.boobleModalData;

    console.log(this.order);
  }

  getTrackingBarCode() {
    console.log("TRACKINGBARCODE:", this.order!.trackingCode!)
    const code = this.order!.trackingCode!;
    if (!code) return 'trackingCode';

    const prefix = code.substring(0, 2).replace(/ /g, '');
    const digits = code.substring(2, code.length - 2).replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '').replace(/ /g, '');
    const suffix = code.substring(code.length - 2).replace(/ /g, '');

    const barCode = `${prefix}${digits}${suffix}`; // Aqui está a correção

    return barCode;
}



  getServiceLogo() {
    switch (this.order!.detail.transporter_job.name.toUpperCase()) {
      case 'PAC':
        return 'assets\\icons\\correios\\pac_preto.png';
      case 'PAC SEGURADO':
        return 'assets\\icons\\correios\\pac_preto.png';
      case 'SEDEX':
        return 'assets\\icons\\correios\\guia_sedex_preto.png';
      case 'SEDEX SEGURADO':
        return 'assets\\icons\\correios\\guia_sedex_preto.png';
      case 'MINI ENVIOS':
        return 'assets\\icons\\correios\\quadrado_preto.png';
      default:
        return 'assets\\icons\\correios\\quadrado_preto.png';
    }
  }

  shipmentId() {
    const shipmentId = this.order!.shipmentId!;
    const start = shipmentId.substring(0, 6);
    const middle = shipmentId.substring(6, 10);
    const end = shipmentId.substring(10, 14);
    return `${start} ${middle} ${end}`;
  }

  getBarcode() {
    const shipmentId = this.order!.shipmentId!;
    const destinationPostalCode =
      this.order!.detail.receiver.address.postalCode;
    return `${shipmentId}$0012${destinationPostalCode}`.replace(' ', '');
  }

  getWeight() {
    return `${this.order!.detail.pack.weight.toString().replace('.', ',')}`;
  }
}
