import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { log } from 'console';

@Component({
  selector: 'app-client-nav-links',
  templateUrl: './client-nav-links.component.html',
  styleUrls: ['./client-nav-links.component.scss'],
})
export class ClientNavLinksComponent implements OnInit {
  @Input('route') route: string = '/encomendas';
  @Output('onNavLinkClicked') onNavLinkClicked = new EventEmitter<string>();

  constructor( private router: ActivatedRoute
     ) {}

  ngOnInit(): void {
    this.route = '/encomendas';
  }

  navigate(path: string) {
    this.onNavLinkClicked.emit(path);
  }
}
